<template>
  <div class="inner-container" :class="{ 'min-container': !getTabs }">
    <div class="page-header">
      <h1>{{ $t("Catégories produits") }}</h1>
      <b-button
        size="sm"
        variant="success"
        class="button-succes-style ml-2 float-right mb-2"
        v-b-modal.CategorieFichierModal
        ><font-awesome-icon icon="plus-circle" /> {{ $t("NEW") }}</b-button
      >
      <b-modal
        id="CategorieFichierModal"
        ref="CategorieFichierModal"
        hide-footer
      >
        <template #modal-header>
          <h5>{{ $t("NEW") }}</h5>
          <b-button size="sm" @click="resetModal()">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="17.028"
              height="17.028"
              viewBox="0 0 17.028 17.028"
            >
              <path
                id="Icon_material-close"
                data-name="Icon material-close"
                d="M24.528,9.215,22.813,7.5l-6.8,6.8-6.8-6.8L7.5,9.215l6.8,6.8-6.8,6.8,1.715,1.715,6.8-6.8,6.8,6.8,1.715-1.715-6.8-6.8Z"
                transform="translate(-7.5 -7.5)"
                fill="#393939"
              />
            </svg>
          </b-button>
        </template>

        <form @submit.prevent="addGatigorie">
          <div class="center">
            <b-form-group :label="$t('WORDING')">
              <b-form-input
                v-model="newCategorie.lib"
                required
                maxLength="50"
              ></b-form-input>
              <div v-if="erreurlist.lib" class="error-message">
                <ul v-if="Array.isArray(erreurlist.lib)">
                  <span v-for="(e, index) in erreurlist.lib" :key="index">
                    {{ e }}
                  </span>
                </ul>
                <span v-else>{{ erreurlist.lib }}</span>
              </div>
            </b-form-group>

            <b-form-group :label="$t('CATMETIER')">
              <multiselect
                v-model="newCategorie.categorie_metier"
                :searchable="true"
                :close-on-select="true"
                :multiple="true"
                :options="getAllcategorieMetier"
                label="lib"
                track-by="id"
              >
                <template slot="noResult">
                  {{ $t("NO_DATA_FOUND") }}
                </template>
              </multiselect>
              <div v-if="erreurlist.categorie_metier" class="error-message">
                <ul v-if="Array.isArray(erreurlist.categorie_metier)">
                  <span
                    v-for="(e, index) in erreurlist.categorie_metier"
                    :key="index"
                  >
                    {{ e }}
                  </span>
                </ul>
                <span v-else>{{ erreurlist.categorie_metier }}</span>
              </div>
            </b-form-group>
          </div>
          <div class="actionModel">
            <b-alert variant="warning" show v-if="errorS" class="messageError">
              {{ errorS }}
            </b-alert>
            <b-button variant="success" type="submit">
              <div class="block-spinner">
                {{ $t("CONFIRM") }}
                <div v-if="getLoadingcategorieArticle" class="loading ml-2">
                  <div class="spinner-border" role="status"></div>
                </div>
              </div>
            </b-button>
          </div>
        </form>
      </b-modal>
    </div>
    <div class="content">
      <div class="content-header">
        <searchBar
          :placeholder="$t('CATEGORY_SEARCH')"
          :searchFunc="allcategorieArticle"
          @returnValue="updateSearch($event)"
        ></searchBar>
      </div>
      <p class="table-count">
        {{ getTotalRowcategorieArticle }} {{ $t("Catégories produits") }}
      </p>
      <div class="table-rapport-style w-100">
        <table>
          <thead style="border-radius: 5px 0px 0px 0px">
            <tr>
              <th>
                {{ $t("WORDING") }}
              </th>
              <th>
                {{ $t("CATMETIER") }}
              </th>
              <th class="action_head"></th>
            </tr>
          </thead>
          <tbody>
            <tr
              role="row"
              class="ligneNormale"
              v-for="(data, i) in getAllcategorieArticle"
              :key="i"
              @click="handleUpdate(data)"
            >
              <td aria-colindex="2" role="cell">
                {{ data.lib }}
              </td>
              <td aria-colindex="2" role="cell" v-if="data.categorie_metier">
                <p v-for="(categorie, i) in data.categorie_metier" :key="i">
                  {{ categorie ? categorie.lib : "" }}
                </p>
              </td>
              <td aria-colindex="3" role="cell" class="action_col">
                <b-button
                  class="button-succes-style"
                  size="sm"
                  variant="danger"
                  @click.prevent.stop="handleDelete(data)"
                >
                  <font-awesome-icon icon="trash" />
                </b-button>
                <b-button
                  class="button-succes-style"
                  size="sm"
                  variant="success"
                  @click.prevent.stop="handleUpdate(data)"
                >
                  <font-awesome-icon icon="pencil-alt" />
                </b-button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <b-modal id="ModelConfigcatig" ref="ModelConfigcatig" hide-footer>
        <template #modal-header>
          <h5>{{ $t("EDIT") }}</h5>
          <b-button size="sm" @click="resetModal()">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="17.028"
              height="17.028"
              viewBox="0 0 17.028 17.028"
            >
              <path
                id="Icon_material-close"
                data-name="Icon material-close"
                d="M24.528,9.215,22.813,7.5l-6.8,6.8-6.8-6.8L7.5,9.215l6.8,6.8-6.8,6.8,1.715,1.715,6.8-6.8,6.8,6.8,1.715-1.715-6.8-6.8Z"
                transform="translate(-7.5 -7.5)"
                fill="#393939"
              />
            </svg>
          </b-button>
        </template>

        <form @submit.prevent="categorieModalNew" v-if="categorieToConfig">
          <div class="center">
            <b-form-group :label="$t('WORDING')">
              <b-form-input
                v-model="categorieToConfig.lib"
                required
              ></b-form-input>
              <div v-if="erreurlist.lib" class="error-message">
                <ul v-if="Array.isArray(erreurlist.lib)">
                  <span v-for="(e, index) in erreurlist.lib" :key="index">
                    {{ e }}
                  </span>
                </ul>
                <span v-else>{{ erreurlist.lib }}</span>
              </div>
            </b-form-group>

            <b-form-group :label="$t('CATMETIER')">
              <multiselect
                v-model="categorieToConfig.categorie_metier"
                :searchable="true"
                :close-on-select="true"
                :multiple="true"
                :options="getAllcategorieMetier"
                label="lib"
                track-by="id"
              >
                <template slot="noResult">
                  {{ $t("NO_DATA_FOUND") }}
                </template>
              </multiselect>
              <div v-if="erreurlist.categorie_metier" class="error-message">
                <ul v-if="Array.isArray(erreurlist.categorie_metier)">
                  <span
                    v-for="(e, index) in erreurlist.categorie_metier"
                    :key="index"
                  >
                    {{ e }}
                  </span>
                </ul>
                <span v-else>{{ erreurlist.categorie_metier }}</span>
              </div>
            </b-form-group>
          </div>

          <div class="actionModel">
            <b-alert variant="warning" show v-if="errorU" class="messageError">
              {{ errorU }}
            </b-alert>
            <b-button variant="success" type="submit">
              <div class="block-spinner">
                {{ $t("EDIT") }}
                <div v-if="getLoadingcategorieArticle" class="loading ml-2">
                  <div class="spinner-border" role="status"></div>
                </div>
              </div>
            </b-button>
          </div>
        </form>
      </b-modal>

      <deleteModalVue
        :loader="getLoadingcategorieArticle"
        :elemToDelete="categorieToDelete"
        :elemDelete="delete_categorieArticle"
        @updateList="setup(false)"
      ></deleteModalVue>

      <b-pagination
        v-if="search == ''"
        v-model="page"
        :total-rows="getTotalRowcategorieArticle"
        :per-page="perPage"
        aria-controls="my-table"
        pills
        last-number
        first-number
        align="center"
        size="sm"
        @change="pagination"
        class=""
      ></b-pagination>
      <b-pagination
        v-else
        v-model="page"
        :total-rows="getTotalRowcategorieArticle"
        :per-page="perPage"
        aria-controls="my-table"
        pills
        last-number
        first-number
        align="center"
        size="sm"
        @change="pagination"
        class=""
      ></b-pagination>
    </div>

    <div class="text-center center-spin">
      <b-spinner
        v-if="getLoadingcategorieArticle"
        variant="primary"
        label="Spinning"
        class="big-spin"
      ></b-spinner>
    </div>
    <CommentMarche to="/how-it-work/categorie"></CommentMarche>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { domains } from "@/environment";
import deleteModalVue from "../components/ui/deleteModal.vue";
import searchBar from "../components/ui/searchBar.vue";
import CommentMarche from "../components/commentMarche.vue";

export default {
  components: {
    searchBar,
    deleteModalVue,
    CommentMarche,
  },
  data() {
    return {
      search: "",
      categorieToConfig: null,
      categorieToDelete: null,
      page: 1,
      perPage: 10,
      newCategorie: {
        lib: null,
        categorie_metier: null,
      },
      erreurlist: {
        lib: null,
        categorie_metier: null,
      },
      favoris: null,
      categorieUrl: domains.Categories,
      errorD: false,
      errorS: false,
      errorU: false,
    };
  },
  methods: {
    ...mapActions([
      "edit_categorieArticle",
      "allcategorieArticle",
      "store_categorieArticle",
      "delete_categorieArticle",
      "allcategorieMetier",
    ]),

    updateSearch(s) {
      this.page = 1;
      this.search = s;
    },
    async searchcategorie() {
      await this.allcategorieArticle({
        search: this.search,
        page: this.page,
        per_page: this.perPage,
      });
    },
    resetModal() {
      this.newCategorie = {
        lib: null,
        categorie_metier: null,
      };
      this.erreurlist = {
        lib: null,
        categorie_metier: null,
      };
      this.categorieToConfig = null;
      this.errorS = null;
      this.errorU = null;
      this.$refs["CategorieFichierModal"].hide();
      this.$refs["ModelConfigcatig"].hide();
    },
    hideModal(ref) {
      this.$refs[ref].hide();
      this.resetModal();
    },
    handleUpdate(CAT) {
      this.categorieToConfig = { ...CAT };
      this.$refs["ModelConfigcatig"].show();
    },
    handleDelete(CAT) {
      this.categorieToDelete = { ...CAT };
      this.$root.$emit("bv::show::modal", "ModelDelete");
    },
    async categorieModalNew() {
      var data = {
        id: this.categorieToConfig.id,
        lib: this.categorieToConfig.lib,
        categorie_metier_id: this.categorieToConfig.categorie_metier.map(
          (item) => item.id
        ),
      };
      await this.edit_categorieArticle(data)
        .then(() => {
          this.hideModal("ModelConfigcatig");
          this.errorU = null;
        })
        .catch((err) => {
          if (this.isObject(err)) {
            for (const [key, value] of Object.entries(err)) {
              if (!this.erreurlist[key]) {
                this.erreurlist[key] = value;
              }
            }
          } else {
            this.errorU = err;
          }
        });
    },
    isObject(obj) {
      return Object.prototype.toString.call(obj) === "[object Object]";
    },
    async addGatigorie() {
      this.errorS = null;
      this.erreurlist = {
        lib: null,
        categorie_metier: null,
      };
      var data = {
        lib: this.newCategorie.lib,
        categorie_metier_id: this.newCategorie.categorie_metier.map(
          (item) => item.id
        ),
      };
      await this.store_categorieArticle(data)
        .then(() => {
          this.errorS = null;
          this.hideModal("CategorieFichierModal");
          this.resetModal();
          this.setup(true);
        })
        .catch((err) => {
          for (const [key, value] of Object.entries(err)) {
            if (!this.erreurlist[key]) {
              this.erreurlist[key] = value;
            } else {
              this.errorS = err;
            }
          }
        });
    },
    pagination(paginate) {
      if (this.search === null || this.search === "") {
        this.page = paginate;
        this.allcategorieArticle({ page: this.page, per_page: this.perPage });
      } else {
        this.page = paginate;
        this.searchcategorie();
      }
    },

    setup(refresh = false) {
      if (refresh) this.page = 1;
      this.search = null;
      this.allcategorieArticle({ page: this.page, per_page: this.perPage });
    },
  },
  mounted() {
    this.setup(true);
    this.allcategorieMetier({ page: 1, per_page: 1000 });
  },
  computed: {
    ...mapGetters([
      "getAllcategorieArticle",
      "getLoadingcategorieArticle",
      "getTotalRowcategorieArticle",
      "getTabs",
      "getAllcategorieMetier",
    ]),
  },
};
</script>

<style lang="scss" scoped>
.content {
  & p {
    font-size: 12px;
    font-weight: 500;
    padding: 3px 0;
    margin: 0;
  }
}
.actionModel {
  position: relative;
  padding: 52px 0 0;
  .messageError {
    position: absolute;
    top: 5px;
    bottom: 44px;
    color: #e4261b;
    margin: 0;
    font-size: 15px;
    background-color: #fff;
    border-color: #fff;
  }
}
.w-100 {
  background-color: #f8f8f8;
  padding: 4px;
  overflow-x: auto;
}

body {
  background: #fafdff;
}
</style>
